<template>
  <v-card flat>
    <!-- display alert/error/warnings -->
    <base-alert v-model="hasError" :dismissible="false">
      <span v-html="currentError"></span>
    </base-alert>

    <v-card-text>
      <!-- Scanning Options -->
      <v-form>
        <v-container fluid>
          <!-- select Scanner -->
          <v-row dense>
            <v-col>
              <v-select
                :items="scannerNames"
                :value="selectedScanner"
                :label="labelSelectedScanner"
                :loading="isLoadingScanners"
              >
                <template v-slot:prepend>
                  <v-icon>{{ iconScanner }}</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Scanning Options -->
          <v-row>
            <v-col>
              <v-expansion-panels accordion multiple>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span v-show="!anyScanningOption"
                      >Enhance image options</span
                    >
                    <core-chip-group
                      v-show="anyScanningOption"
                      :items="selectedEnhancementOptionLabels"
                      :column="true"
                      :chip-small="true"
                    >
                    </core-chip-group>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col
                        ><v-checkbox
                          dense
                          v-model="selectedEnhancementOptions"
                          :label="
                            imageEnhancementLabel(enhancementNames.autoRotate)
                          "
                          :value="enhancementNames.autoRotate"
                        ></v-checkbox
                      ></v-col>
                      <v-col
                        ><v-checkbox
                          dense
                          v-model="selectedEnhancementOptions"
                          :label="
                            imageEnhancementLabel(enhancementNames.deskew)
                          "
                          :value="enhancementNames.deskew"
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                    <v-row dense>
                      <v-col
                        ><v-checkbox
                          dense
                          v-model="selectedEnhancementOptions"
                          :label="
                            imageEnhancementLabel(enhancementNames.despeckle)
                          "
                          :value="enhancementNames.despeckle"
                        ></v-checkbox
                      ></v-col>
                      <v-col
                        ><v-checkbox
                          dense
                          v-model="selectedEnhancementOptions"
                          :label="
                            imageEnhancementLabel(enhancementNames.autoCrop)
                          "
                          :value="enhancementNames.autoCrop"
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                    <v-row dense>
                      <v-col
                        ><v-checkbox
                          dense
                          v-model="selectedEnhancementOptions"
                          :label="
                            imageEnhancementLabel(enhancementNames.holeFill)
                          "
                          :value="enhancementNames.holeFill"
                        ></v-checkbox
                      ></v-col>
                      <v-col
                        ><v-checkbox
                          dense
                          v-model="selectedEnhancementOptions"
                          :label="
                            imageEnhancementLabel(
                              enhancementNames.discardBlankPages
                            )
                          "
                          :value="enhancementNames.discardBlankPages"
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <!-- acquired Image info -->
          <v-row v-show="acquiredImageCount > 0">
            <v-col>
              <span>{{ acquiredImageInfo }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <!-- progress - uploading/check-in scanned image file -->
      <progress-display v-if="visibleUploadProgress">
        {{ uploadProgressInfo }}
      </progress-display>

      <!-- display upload error-->
      <base-alert v-model="hasUploadError" :dismissible="false">
        <span v-html="uploadError"></span>
      </base-alert>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <!-- Scan Command button -->
      <base-tooltip-button @click="scan" :disabled="!enabledScanCommand">
        <template v-slot:default>
          {{ scanCommandName }}
        </template>
        <template v-slot:toolTip>
          {{ scanCommandTooltip }}
        </template>
      </base-tooltip-button>
    </v-card-actions>

    <!-- snackbar -->
    <snackbar
      v-model="snackbarVisible"
      @close="closeSnackbar"
      :color="snackbarColor"
      :top="snackbarTop"
      :timeout="snackbarTimeout"
      :multi-line="snackbarMultiLine"
      >{{ snackbarText }}</snackbar
    >

    <!-- WebDocumentViewer -->
    <div
      ref="viewerContainer"
      class="atala-document-container px-3 border-left border-light"
    ></div>
  </v-card>
</template>
<script>
//services
import { wdvUrl } from "@/services/config/configService";

// model
import { findImageEnhancement } from "@/model/acquire/scan/scanningOptions";

// mixins
import { snackbarableMixin } from "@/mixins/shared/snackbarable/snackbarableMixin";
import { wingScanMixin } from "@/mixins/scan/wingScanMixin";

/*eslint no-undef: "OFF"*/
export default {
  name: "DocumentScan",
  mixins: [snackbarableMixin, wingScanMixin],
  components: {
    BaseAlert: () => import("@/components/shared/base/BaseAlert"),
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton"),
    CoreChipGroup: () =>
      import("@/components/shared/core/chipGroup/CoreChipGroup"),
    ProgressDisplay: () =>
      import("@/components/shared/core/progress/ProgressDisplay")
  },
  data() {
    return {
      // WebDocumentViewer
      documentView: undefined,
      thumbnailsView: undefined
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    record: undefined,
    checkingInScannedImage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Overwrite Abstract computed basicScanMixin.currentRecord
     * @return {undefined}
     */
    currentRecord() {
      return this.record;
    },
    /**
     * visible Upload Progress
     * @return {Boolean}
     */
    visibleUploadProgress() {
      return this.checkingInScannedImage || this.isUploadingPages;
    }
  },
  methods: {
    /**
     * create WebDocumentViewer
     */
    createWebDocumentViewer() {
      try {
        // Web Document Viewer
        // $(".atala-document-container")
        // $(".atala-document-toolbar"
        this.documentView = new Atalasoft.Controls.WebDocumentViewer({
          parent: $(".atala-document-container"),
          toolbarparent: $(".atala-document-toolbar"),
          allowforms: true,
          allowannotations: true,
          showbuttontext: false,
          allowtext: true,
          serverurl: wdvUrl,
          forcepagefit: true
        });

        // Display toolbar buttons without text
        $(".atala-ui-button .ui-button-text").html(" ");
        $(".atala-ui-button").css({ height: "28px", width: "28px" });

        // Web Document Thumbnails
        // $(".atala-document-thumbnailer")
        this.thumbnailsView = new Atalasoft.Controls.WebDocumentThumbnailer({
          parent: $(".atala-document-thumbnailer"),
          serverurl: wdvUrl,
          allowannotations: true,
          viewer: this.documentView
        });
      } catch (e) {
        this.showSnackbarError(e.toString(), true);
      }
    },

    /**
     * prepare new WebDocumentViewer Document
     */
    newWebDocumentViewerDocument() {
      try {
        if (this.documentView) {
          this.documentView.empty();
        } else {
          this.createWebDocumentViewer();
        }
      } catch (e) {
        this.showSnackbarError(e.toString(), true);
      }
    },

    /**
     * Overwrite basicScanMixin.afterUploadCompleted
     * @param {boolean} success
     */
    afterUploadCompleted(success) {
      try {
        this.newWebDocumentViewerDocument();

        /**
         * if success, Display scanned images
         */
        if (success) {
          this.$emit("uploadedScannedImages", this.acquiredImageCount);

          // Define document open url
          const url = `${this.currentRecord?.id ?? -1};${
            this.currentUser?.token
          }`;

          this.thumbnailsView.OpenUrl(url);
        } else {
          console.warn(
            `Couldn't display uploaded scanned images. Upload images Success: `,
            success
          );
        }
      } catch (e) {
        this.setUploadError(e);
      } finally {
        this.isUploadingPages = false;
      }
    },

    /**
     * Callback to be called in case of successful completion of the operation.
     */
    disposeSuccessCallback() {
      try {
        console.log(`WebScanning disposed successfully`);
      } catch (e) {
        console.error(e.toString());
      }
    },

    /**
     * Callback to be called in case of any errors during dispose.
     */
    disposeErrorCallback() {
      try {
        console.log(`WebScanning encounter some problems while  disposing`);
      } catch (e) {
        this.setCurrentError(e.toString());
      }
    },

    /**
     * get image EnhancementLabel
     * @param {string} name Image Enhancement name
     * @return {string} Image Enhancement Label
     */
    imageEnhancementLabel(name) {
      return findImageEnhancement(name)?.label;
    }
  },

  watch: {
    /**
     * watch visible of Document Scan dialog
     * @param {boolean} newValue
     * @return {Promise<void>}
     */
    async visible(newValue) {
      console.log(`Document Scan visible:`, newValue);

      // Performers Cleanup here ...
      this.resetScanVariables();

      if (newValue) {
        try {
          await this.initializeWebScanning();
        } catch (e) {
          console.error(e.toString());
        }
      } else {
        // dispose documentView
        try {
          // Removes all DOM elements, internal references, and empties memory intensive objects.
          // The WebDocumentViewer will no longer function after a call to this method
          this.documentView?.dispose();
          console.log(`WebDocumentViewer disposed`);
        } catch (e) {
          console.error(`Couldn't dispose WebDocumentViewer. ${e}`);
        }

        // dispose WebDocumentThumbnailer
        try {
          // Removes all DOM elements, internal references, and empties memory intensive objects.
          // The WebDocumentThumbnailer will no longer function after a call to this method
          this.thumbnailsView?.dispose();
          console.log(`WebDocumentThumbnailer disposed`);
        } catch (e) {
          console.error(`Couldn't dispose WebDocumentThumbnailer. ${e}`);
        }

        // Aborts the current background operation in progress
        this.abortScan();

        // shutdown all Web Capture services
        this.disposeScanning();
      }
    }
  },

  /**
   * created DocumentScan component event handler
   */
  async created() {
    await this.initializeWebScanning();
  }
};
</script>
